import * as React from 'react';
import { createClasses } from 'styles';
import { useDialog } from 'react-app/components/DialogProvider';
import Stack from '@mui/material/Stack';
import MegaphoneIcon from 'react-app/icons/MegaphoneCircle';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PageSection from 'components/PageSection';
import { HeroBannerHeadline, HeroBannerButton } from 'components/HeroBanner';
import { appData } from 'config/data.cjs';

const PageHeadlineProps = {
	sx: {'&&': {
		justifyContent: 'center'
	}}
};

const ContainerProps = {
	backgroundColor: '#272727'
};

const useClasses = createClasses((theme) => ({
	heading: {
		...theme.mixins.goldText
	},
	subheading: {
		fontWeight: theme.typography.fontWeightMedium
	},
	button: {
		...theme.mixins.goldButton,
		margin: 0
	}
}), {
	name: 'AnniversaryRoute'
});

function AnniversarySection(props) {
	const {
		ContainerProps: ContainerPropsProp,
		PageHeadlineProps: PageHeadlinePropsProp,
		revoke,
		...rest
	} = props;

	const classes = useClasses(props);
	const { openDialog } = useDialog();

	return (
		<PageSection
			{...rest}
			ContainerProps={{...ContainerProps, ...ContainerPropsProp}}
			PageHeadlineProps={{...PageHeadlineProps, ...PageHeadlinePropsProp}}
		>
			<Stack gap={{xs: 2, lg: 3}}>
				<HeroBannerHeadline
					noTextStroke
					classes={{
						root: classes.heading,
						subheading: classes.subheading
					}}
					title="Verpassen Sie nicht unsere Jubiläumsangebote"
					subtitle="Feieren Sie mit uns 100 Jahre Holz Weidauer"
				/>
				<Stack direction={{sm: 'row'}} gap={{xs: 2, lg: 3}} margin="auto">
					<HeroBannerButton
						className={classes.button}
						color="primary"
						href={appData.pages.anniversary.pathname}
						startIcon={<MegaphoneIcon/>}
					>
						Jubiläumsangebote
					</HeroBannerButton>
					<HeroBannerButton
						className={classes.button}
						color="primary"
						onClick={openDialog}
						data-src="https://youtube.com/embed/6ZAVQocEx0U?autoplay=1&rel=0"
						data-title="Wir sind Holz Weidauer - Unsere Firmengruppe stellt sich vor"
						startIcon={<YouTubeIcon/>}
					>
						Wir stellen uns vor
					</HeroBannerButton>
				</Stack>
			</Stack>
		</PageSection>
	);
}

export default React.memo(AnniversarySection);
