import HeroBanner, { HeroBannerLogo, HeroBannerHeadline } from 'components/HeroBanner';
import { logo_hw_outlined, logo_hw_retail_outlined, default1_banner, wood1_banner } from 'config/images';
import { useDeferredUser } from 'context/user';

const LogoProps = {
	width: {xs: 200, md: 300}
};
const HeroBannerProps = {
	sx: {
		'&&': {
			maxHeight: {xs: 200, sm: 220, desktop: 250},
			minHeight: {xs: 200, sm: 220, desktop: 250}
		}
	}
};

export default function HomePageHeroBanner() {
	const [ {isRetail} ] = useDeferredUser();

	return (
		<HeroBanner {...HeroBannerProps} image={isRetail ? default1_banner : wood1_banner}>
			<HeroBannerLogo ImgProps={isRetail ? logo_hw_retail_outlined : logo_hw_outlined} LogoProps={LogoProps} justifyContent="space-around">
				<HeroBannerHeadline
					gutterTop={false}
					title={isRetail ? 'Ihr Holzfachmarkt mit Ausstellung' : 'Ihr Holzgroßhandel mit Ausstellung'}
					subtitle={isRetail ? 'für Heimwerker und Profis' : 'für Handwerker und Architekten'}
					component="h1"
				/>
			</HeroBannerLogo>
		</HeroBanner>
	);
}
